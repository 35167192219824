<template>
  <div class="row">
    <div class="margen" style="display: flex; width:100%;">
      <div v-if="tableName != 'Tabla'" style="display: flex; width:100%; justify-content: flex-start;">
          <hr class="vr"/>
          <p class="tblName pb-2">{{tableName}}</p>
      </div>
      <div style="display: flex; width:100%; justify-content: flex-end;">
        <slot name="actionButtonsLeft" v-if="actionButtonsLeftSlot"></slot>
        <div
          class="icon-container d-flex align-center justify-center"
          @click="openModal()"
          v-if="showAdvancedFilters"
        >
          <v-icon>mdi-magnify</v-icon>
        </div>
        <slot name="actionButtonsRight" v-if="actionButtonsRightSlot"></slot>
      </div>
    </div>
    <div  style="width:100%;overflow-x:auto;" id="tableScrollX">
      <vue-table
        ref="tabla"
        @sort="sortBy"
        @selectAll="selectAll"
        :selectable="isSelectable"
        :sortKey="sortKey"
        :Order="Order"
        :columns="columns"
        :idTabla="idTabla"
        :sortOrders="sortOrders"
        :table-classes="classes.table"
        :table-header-classes="classes['t-head']"
        :table-row-classes="classes['t-head-tr']"
        :table-container-classes="classes['table-container']"
        :modalTableBool="modalTable"
        :disabledCheck="disabledCheck"

      >
        <template v-if="bodySlot">
          <template v-if="isLoading">
            <tbody>
              <tr>
                <td :colspan="columns.length">
                  <v-progress-linear
                    :active="isLoading"
                    class="loading"
                    color="blue"
                    indeterminate
                  ></v-progress-linear>
                </td>
              </tr>
            </tbody>
          </template>

          <template v-else-if="data.data.length == 0">
              <tbody>
                  <tr>
                      <td :class="emptyTable" :colspan="columns.length">
                          No hay elementos para mostrar
                      </td>
                  </tr>
              </tbody>
            </template>

          <template v-else>
            <slot name="body" v-if="bodySlot" :data="data.data"></slot>
          </template>
        </template>

        <template v-else>
          <tbody v-if="!!columns" :class="classes['t-body']">
            <tr
              :key="item.id"
              v-for="item in data.data"
              :class="classes['t-body-tr']"
            >
              <td
                :key="column.name"
                :class="classes.td"
                v-for="column in columns"
              >
                <data-table-cell
                  :value="item"
                  :name="column.name"
                  :meta="column.meta"
                  :event="column.event"
                  :comp="column.component"
                  :classes="column.classes"
                  :handler="column.handler"
                ></data-table-cell>
              </td>
            </tr>
          </tbody>
        </template>
      </vue-table>
    </div>
    <div :class="['display-row', 'pagination-container', modalTable?'modal':'', classPagination?classPagination:'']">
      <div>
        <slot
          :meta="data.meta"
          name="pagination"
          :links="data.links"
          v-if="paginationSlot"
        ></slot>
        <data-table-pagination
          v-else
          @getPageAction="getPageAction"
          :short="shortPagination"
          :from="data.from != null ? data.from : 0"
          :to="data.to != null ? data.to : 0"
          :total="data.total"
          :currentPage="data.current_page"
          :lastPage="data.last_page"
          :linkFirst="data.first_page_url"
          :linkPrev="data.prev_page_url"
          :linkNext="data.next_page_url"
          :linkLast="data.last_page_url"
          :perPage="perPage"
          :tableData="tableData"
          :modalTableBool="modalTable"
        ></data-table-pagination>
      </div>
    </div>

    <template>
      <v-layout row justify-center>
        <v-dialog v-model="dialogFilters" persistent max-width="600px">
          <template v-slot:activator="{ on }">
            <v-toolbar-title v-on="on">
              <v-btn class="botonModal" id="btnBusquedaAvanzada"></v-btn>
            </v-toolbar-title>
          </template>

          <v-card>
            <v-card-title class="card_titulo padding_derecho padding_izquierdo pb-5">
              <div class="headerModal-filter">
                <h2 class="titleModalBuscar">{{ modalTitle }}</h2>
              </div>
            </v-card-title>

            <v-card-text class="card_texto pb-3" @keyup.enter="filter()">
              <v-container grid-list-md id="contenedor">
                <v-row>
                  <slot name="filters" v-if="filtersSlot"></slot>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions
              class="d-flex justify-end card_accion"
            >
              <v-spacer></v-spacer>
              <button @click="closeModal()" class="btnCancelar">
                Cerrar
              </button>
              <button @click="filter()" class="btnBuscar">
                Buscar
              </button>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import UrlFilters from "./mixins/UrlFilters";
import VueTable from "./Table.vue";
import DataTableCell from "./DatatableCell.vue";
import DataTableFilters from "./DatatableFilters.vue";
import DataTablePagination from "./DatatablePagination.vue";
import PerfectScrollbar from 'perfect-scrollbar';

export default {
  created() {
    if (this.addFiltersToUrl) {
      this.checkParameters(this.tableData);
    } else {
      this.getData();
    }
  },
  mounted() {
    this.columns.forEach((column) => {
      this.sortOrders[column.name] = -1;
    });
    this.tableScroll();
  },
  updated(){
    this.tableScroll();
  },
  mixins: [UrlFilters],
  watch: {
    filters: {
      handler: function() {
        //console.log("filters");
        this.getData();
        
      },
    },
    url: {
      handler: function(newUrl) {
        //console.log("url",newUrl);
        if (newUrl.includes("page=")) {
          this.getData(newUrl);
        }
        
      },
    },
    "tableData.items_to_show": function(nuevo, viejo) {
     // console.log("items to show");
      if (nuevo != viejo) {
        if (this.url.includes("page=")) {
          let urlSplit = this.url.split("page=");
          this.getData(urlSplit[0] + "page=1");
        } else {
          this.getData();
        }
      }
    },
  },
  components: {
    "vue-table": VueTable,
    "data-table-cell": DataTableCell,
    "data-table-filters": DataTableFilters,
    "data-table-pagination": DataTablePagination,
  },
  data() {
    return {
      data: {},
      selectedItems:[],
      checkAll:false,
      isLoading: false,
      sortKey: "id",
      Order  : 'desc',
      sortOrders: {},
      draw: 0,
      tableData: {
        paginate: true,
        items_to_show: this.perPage[0],
      },
      dialogFilters: false,
      imagen: " /static/modal/busqueda_general.svg",
      tableName: this.tableName
    };
  },
  props: {
    valCheck:{
      type: String,
      default: "empleado_id",
    },
    idTabla: {
      type: String,
      default: "tableScroll",
    },
    tableName: {
      type :String,
      default: "Tabla"
    },
    emptyTable:{
      type :String,
      default: "emptyTable"
    },
    url: {
      type: String,
      default: "/",
      required: true,
    },
    orderBy: {
      type: String,
      default: "id",
    },
    showAdvancedFilters: {
      type: Boolean,
      default: true,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    modalTitle: {
      type: String,
      default: "Búsqueda avanzada",
    },
    addFiltersToUrl: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => [],
      required: true,
    },
    pagination: {
      type: Object,
      default: () => ({
        limit: 1,
        align: "right",
      }),
    },
    perPage: {
      type: Array,
      default: () => ["10", "25", "50"],
    },
    orderDir: {
      type: String,
      default: "asc",
      validator: function(value) {
        return ["asc", "desc"].indexOf(value) !== -1;
      },
    },
    shortPagination: {
      type: [Boolean],
      default: false,
    },
    modalTable: {
      type: [Boolean],
      default: false,
    },
    classPagination:{
      type:[String],
      default: ''
    },
    classes: {
      type: Object,
      default: () => ({
        "table-container": {
          "table-responsive": true,
        },
        table: {
          table: true,
          "table-striped": true,
          "table-dark": true,
        },
        "t-head": {},
        "t-body": {},
        td: {},
        th: {},
      }),
    },
    isSelectable: {
      type: [Boolean],
      default: false,
    },
    unselected: {
      type: Array,
      default: () => ([]),
    },
    disabledCheck: {
        type: Boolean,
        default: false
    }
  },
  methods: {
    getData(url = this.url) {
      this.isLoading = true;
      url = this.checkUrlForPagination(url);
      //this.incrementDraw();
      Vue.axios
        .get(url, this.getRequestPayload)
        .then((response) => {
          if (response) {
            let data = response.data;
            //if (this.checkTableDraw(data.payload.draw)) {
            this.isLoading = false;
            this.data = data;
            this.selectAll(this.checkAll);
            if (this.addFiltersToUrl) {
              this.updateParameters(this.tableData);
            }
            this.$emit('datosTabla', data);
            //}
          }
        })
        .catch((errors) => {
          this.isLoading = false;
          alert(errors);
        });
    },
    async sortBy(key, columnName = null) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;

      this.tableData.column   = columnName ? columnName : key;
      this.Order = this.sortOrders[key] === 1 ? 'asc' : 'desc';

      let columnaOrden = {
        order_by: this.sortKey,
        order:this.Order
      };
      
      Object.assign(this.getRequestPayload.params, columnaOrden);
            
      await this.getData();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    incrementDraw() {
      this.draw++;
    },
    checkTableDraw(draw) {
      if (this.draw == draw) {
        return true;
      }
      return false;
    },
    checkUrlForPagination(url) {
      if (Number.isInteger(url)) {
        url = this.url + "?page=" + url;
        return url;
      }
      return url;
    },
    getPageAction(value) {
      this.url = value;
    },
    openModal() {
      this.$emit("loadModalData");
      setTimeout(function() {
        document.getElementById("btnBusquedaAvanzada").click();
      }, 100);
    },
    closeModal() {
      this.dialogFilters = false;
    },
    filter() {
      this.$emit("setFilters");
      this.$emit("busquemos");
      this.closeModal();
    },
    tableScroll(){
        const elementScrollX = document.querySelector('#tableScrollX');
        if(elementScrollX) {
            const ps = new PerfectScrollbar(elementScrollX, {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20
            });
        }
    },
    unselectAll() {
      this.$refs.tabla.selectAll = false;
    },
    selectAll(value) {
      this.checkAll = value;
      if(value) {
          for (let item of this.data.data) {
            if(!this.selectedItems.includes(item[this.valCheck]))
              if(!this.unselected.includes(item[this.valCheck]))
                this.selectedItems.push(item[this.valCheck]);
          }
      } else {
          this.selectedItems = [];
      }
//console.log(this.selectedItems);
      this.$emit('selectAllEvent', value, this.selectedItems);
    },
  },
  computed: {
    paginationSlot() {
      if (this.$scopedSlots) {
        return this.$scopedSlots.pagination;
      }
      return null;
    },
    filtersSlot() {
      if (this.$scopedSlots) {
        return this.$scopedSlots.filters;
      }
      return null;
    },
    bodySlot() {
      if (this.$scopedSlots) {
        return this.$scopedSlots.body;
      }
      return null;
    },
    actionButtonsLeftSlot() {
      if (this.$scopedSlots) {
        return this.$scopedSlots.actionButtonsLeft;
      }
      return null;
    },
    actionButtonsRightSlot() {
      if (this.$scopedSlots) {
        return this.$scopedSlots.actionButtonsRight;
      }
      return null;
    },
    getRequestPayload() {
      let payload = Object.assign({}, this.tableData);
      delete payload.filters;
      //payload         = Object.assign(payload, this.tableData.filters);
      //payload.draw    = this.draw;

      payload = Object.assign(payload, this.filters);
      return {
        params: payload,
      };
    },
  },
};
</script>

<style scoped>
    .emptyTable{
        padding-top: 50px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #c5c5c5;
    }
    .emptyTableWhite{
        text-align: center;
        font-size: 18px;
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: #ffffff;
        color: #c5c5c5;
    }
    .display-row {
        /* display: inline-block; */
        text-align: center;
    }
    .display-row.pagination-container {
        width: 100%;
        text-align: right;
        background-color: #fdfcfc;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        padding: 0px 43px;
        padding-top: 20px;

        box-shadow: 4px 4px 8px rgba(196, 196, 196, 0.2);
    }
    .display-row.pagination-container.modal {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        box-shadow: none;
        background-color: white;
    }
    .display-row.pagination-container.box-shadow-none {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        box-shadow: none;
    }
    .display-row.pagination-container > div {
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: #c4c4c4;
    }
    /**Fin contenedor paginacion */

    .pull-right {
        /* text-align: right !important; */
        float: right;
    }

    /**Cambios de estilos tabla kardex */
    .margen {
        margin-bottom: 20px;
        margin-right: 10px;
    }
    /**fin cambios de estilos tabla kardex */
    .icon-container {
        width: 42px;
        height: 42px;
        background: #ffffff;
        border-radius: 100%;
        text-align: center;
        padding: 11px;
        transition:0.3s;
        }

    .icon-container:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        
    }
    

#btnBusquedaAvanzada {
  display: none !important;
}

/* Cambios manuel */
.titleModalBuscar {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #1E2245;
  padding-bottom: 25px;
}

.btnBuscar{
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 18px !important;
  background-color: #1E2245 !important;
  border-radius: 30px !important;
  color: white !important;
  width: 110px !important;
  height: 40px !important;
  outline: none;
  text-transform: none !important;
  margin-left: 3%; /* euicab separacion entre guardar y cancelar botones */
  transition: all .2s;
}

.btnCancelar {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  text-align: center !important;
  letter-spacing: 0.2px !important;
  background-color: #EEEEEE !important;
  color:#828282 !important;
  /*margin-right: 3%;euicab eliminamos el margen
  margin-top: 2%;*/
  outline: none;
  border-radius: 30px !important;
  width: 110px !important;
  height: 40px !important;
  transition: all .2s;
}
/* euicab se aplica un hover al boton cancelar */
.btnCancelar:hover {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  text-align: center !important;
  letter-spacing: 0.2px !important;
  background-color: #c7cacc !important;
  color: #FFF !important;
  /*margin-right: 1%;
  margin-top: 2%;*/
  outline: none;
  border-radius: 30px !important;
  width: 110px !important;
  height: 40px !important;
} 
 
@media only screen and (max-width: 743px) {
 .display-row.pagination-container {
        width: 100%;
        text-align: center;
        padding: 0px 0px;
    }
   
}
</style>
